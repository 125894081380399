<div *nzModalTitle>
  <span>{{ 'LOT_BIDDING_MODAL.PLACE_YOUR_BID' | translate }}</span>
  <button
    class="guide-btn"
    nz-tooltip
    [nzTooltipTitle]="'HINT' | translate"
    nzTooltipPlacement="right"
    nz-button
    nzType="default"
    nzShape="circle"
    appDriverGuide
    (click)="startDriver()"
  >
    <span nz-icon nzType="question" nzTheme="outline"></span>
  </button>
</div>

<nz-spin
  nzSize="large"
  [nzSpinning]="(limits.loading$ | async) || (biddingDataQueryRef.loading | async)"
>
  <main>
    <app-alerts-list-manual
      class="alerts-list-manual"
      *ngIf="(biddingDataQueryRef.data | async)?.alerts?.length"
      [alerts]="(biddingDataQueryRef.data | async)?.alerts"
    ></app-alerts-list-manual>
    <nz-alert
      nzShowIcon
      nzType="warning"
      *ngIf="mayNotBeProcessed"
      [nzMessage]="'BID_MAY_NOT_BID_PROCESSED' | translate"
    ></nz-alert>
    <nz-alert
      nzType="warning"
      nzShowIcon
      *ngIf="withLimitsOverride"
      nzMessage="Дополнительная комиссия 1% стоимости лота (не менее 10 000 йен)"
    ></nz-alert>
    <nz-alert
      *ngIf="limits.canPalaceOnlyOneBid$ | async"
      nzType="warning"
      nzShowIcon
      [nzMessage]="'LOT_BIDDING_MODAL.CAN_PACE_ONLY_ONE_BID' | translate"
    ></nz-alert>
    <form nz-form [formGroup]="form">
      <app-max-bid-amount-alert
        [maxBidAmountLimit]="(biddingDataQueryRef.data | async)?.currentUser?.maxBidAmountLimit"
      ></app-max-bid-amount-alert>
      <nz-form-item [nzGutter]="[3, 0]">
        <nz-form-control>
          <nz-input-number
            class="w-100"
            id="0"
            type="text"
            #nzInputNumber
            formControlName="amount"
            nzPlaceHolder="{{ 'LOT_BIDDING_MODAL.YOUR_BID' | translate }}"
            [nzMin]="0"
            [nzStep]="10000"
            [nzParser]="parserYen"
            [nzFormatter]="formatterYen"
            (nzFocus)="nzInputNumber.inputElement.nativeElement.select()"
            appDriverStep
            [overrideStepIndex]="0"
            [stepTitle]="'LOT_BIDDING_MODAL.GUIDE.BID_AMOUNT.TITLE' | translate"
            [stepDescription]="'LOT_BIDDING_MODAL.GUIDE.BID_AMOUNT.DESCR' | translate"
            [stepSide]="'left'"
            [stepAlign]="'start'"
            [popoverClass]="'lot-bid-modal'"
            (onNextClick)="onNextClickAmount()"
          ></nz-input-number>
          <app-bid-amount-score
            *ngIf="form.value.amount > 0 && lot?.frame"
            [lot]="lot"
            [amount]="form.value.amount"
            (bidMakeSense)="this.bidMakeSenseChange$.next($event)"
          ></app-bid-amount-score>
        </nz-form-control>

        <div id="groupBids2" class="ant-form-item-control pos-relative" #groupBids>
          <nz-form-control
            *ngIf="!isNewGroup && (biddingDataQueryRef.data | async)?.isCanGroupBids"
          >
            <nz-input-group class="d-flex" nzCompact>
              <nz-select
                id="newGroupBids"
                *ngIf="(biddingDataQueryRef.data | async)?.bidGroups?.length"
                formControlName="groupId"
                style="width: 90%"
                nzPlaceHolder="{{ 'LOT_BIDDING_MODAL.NO_GROUP' | translate }}"
              >
                <nz-option
                  [nzValue]="''"
                  nzLabel="{{ 'LOT_BIDDING_MODAL.NO_GROUP' | translate }}"
                ></nz-option>
                <nz-option
                  *ngFor="let group of (biddingDataQueryRef.data | async)?.bidGroups"
                  [nzValue]="group.id"
                  [nzLabel]="getBidGroupLabel(group)"
                  [nzDisabled]="group.lotsLimitReached && !currentUserLastBid"
                ></nz-option>
              </nz-select>
              <button
                appDriverStep
                [overrideStepIndex]="1"
                [stepTitle]="'LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.ZERO.TITLE' | translate"
                [stepDescription]="'LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.ZERO.DESCR' | translate"
                [stepSide]="'bottom'"
                [stepAlign]="'start'"
                [popoverClass]="'lot-bid-modal'"
                [showButtons]="['close']"
                id="newGroupBtn1"
                nz-button
                nzBlock
                type="button"
                nzType="primary"
                (click)="createNewGroup()"
              >
                {{ 'LOT_BIDDING_MODAL.NEW_GROUP' | translate }}
              </button>
            </nz-input-group>
          </nz-form-control>

          <nz-form-control
            class="pos-relative"
            *ngIf="isNewGroup && (biddingDataQueryRef.data | async)?.isCanGroupBids"
            formGroupName="newGroup"
          >
            <nz-input-group class="d-flex" nzCompact>
              <nz-select style="width: 50%" formControlName="letter" nzPlaceHolder="Letter">
                <nz-option
                  *ngFor="let letter of (biddingDataQueryRef.data | async)?.allowedLetters"
                  [nzValue]="letter"
                  [nzLabel]="letter"
                ></nz-option>
              </nz-select>
              <nz-input-number
                type="text"
                id="quantity"
                [nzMin]="1"
                [nzMax]="9"
                nzPlaceHolder="{{ 'LOT_BIDDING_MODAL.QUANTITY' | translate }}"
                formControlName="quantity"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
          <button
            [ngClass]="{
              hide: !(isNewGroup && (biddingDataQueryRef.data | async)?.isCanGroupBids)
            }"
            #delNewGroupRef
            id="removeNewGroup"
            nz-button
            type="button"
            nzType="primary"
            nzDanger
            (click)="removeNewGroup()"
          >
            <i nz-icon nzType="close" nzTheme="outline"></i>
          </button>
        </div>
      </nz-form-item>

      <app-commission-agreement-control
        [lot]="lot"
        [amount]="form.value.amount"
        [maxBidAmount]="(biddingDataQueryRef.data | async)?.maxBidAmount"
        [minBidCommission]="(biddingDataQueryRef.data | async)?.minBidCommission"
        [isOtherCountriesUser]="(biddingDataQueryRef.data | async)?.currentUser?.isOtherCountries"
        (maxAmountReached)="maxBidLimitReached$.next($event)"
        formControlName="commissionAgreement"
      ></app-commission-agreement-control>

      <lib-minimal-jp-calculator
        id="5"
        *ngIf="
          renderCalculator && !(biddingDataQueryRef.data | async)?.currentUser?.isOtherCountries
        "
        appDriverStep
        [overrideStepIndex]="5"
        [stepTitle]="'LOT_BIDDING_MODAL.GUIDE.CALC.TITLE' | translate"
        [stepDescription]="'LOT_BIDDING_MODAL.GUIDE.CALC.DESCR' | translate"
        [stepSide]="'left'"
        [stepAlign]="'start'"
        [popoverClass]="'lot-bid-modal'"
        [allowMovePrev]="false"
        (onPrevClick)="onPrevCalc()"
        (onNextClick)="onNextCalc()"
        [amount]="form.value.amount"
        [auctionId]="(biddingDataQueryRef.data | async)?.auction?.id"
        [lotType]="lotType"
      ></lib-minimal-jp-calculator>

      <div *ngIf="!((biddingDataQueryRef.data | async)?.isCanGroupBids ?? true)">
        {{ 'LOT_BIDDING_MODAL.TENDER_AUCTION_NO_GROUP' | translate }}
      </div>

      <nz-form-item>
        <nz-form-control>
          <nz-select
            appDriverStep
            [overrideStepIndex]="6"
            [stepTitle]="'LOT_BIDDING_MODAL.GUIDE.CONDITION.TITLE' | translate"
            [stepDescription]="'LOT_BIDDING_MODAL.GUIDE.CONDITION.DESCR' | translate"
            [stepSide]="'left'"
            [stepAlign]="'start'"
            [popoverClass]="'lot-bid-modal'"
            id="6"
            formControlName="conditionId"
            (ngModelChange)="isDisassemblyCheck($event)"
            nzPlaceHolder="{{ 'LOT_BIDDING_MODAL.SELECT_CONDITIONS' | translate }}"
          >
            <nz-option
              *ngFor="let condition of (biddingDataQueryRef.data | async)?.conditions"
              [nzValue]="condition.id"
              [nzLabel]="condition.title | translate"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="canOrderPriorityPass$ | async">
        <nz-form-control>
          <div>
            <nz-radio-group formControlName="needPriorityPass" nzButtonStyle="solid">
              <label nz-radio-button [nzValue]="false">
                {{ 'LOT_BIDDING_MODAL.DEFAULT_DELIVERY' | translate }}
              </label>
              <label nz-radio-button [nzValue]="true">
                {{ 'LOT_BIDDING_MODAL.PRIORITY_PASS' | translate }}
              </label>
            </nz-radio-group>
            <a
              nz-button
              nzType="link"
              class="priority-pass-info-button"
              (click)="openPriorityPassInfoModal()"
            >
              <i nz-icon nzType="question-circle" nzTheme="outline"></i>
            </a>
          </div>
          <div *ngIf="form.value.needPriorityPass">
            ~1000 USD (<a (click)="openPriorityPassInfoModal()">{{ 'DETAILS' | translate }}</a
            >)
          </div>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control>
          <nz-input-group>
            <textarea
              id="7"
              appDriverStep
              [overrideStepIndex]="7"
              [stepTitle]="'LOT_BIDDING_MODAL.GUIDE.COMMENT.TITLE' | translate"
              [stepDescription]="'LOT_BIDDING_MODAL.GUIDE.COMMENT.DESCR' | translate"
              [stepSide]="'left'"
              [stepAlign]="'start'"
              [popoverClass]="'lot-bid-modal'"
              formControlName="comment"
              nz-input
              rows="2"
              placeholder="{{ 'LOT_BIDDING_MODAL.YOUR_COMMENT' | translate }}"
            ></textarea>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-alert
        nzType="warning"
        nzShowIcon
        class="w-100"
        *ngIf="bidDelete1100prohibited"
        [nzMessage]="message1100"
      ></nz-alert>
      <nz-alert
        nzType="warning"
        nzShowIcon
        class="w-100"
        *ngIf="bidDelete1700prohibited"
        [nzMessage]="message1700"
      ></nz-alert>
      <ng-template #message1100>
        <p style="font-size: 14px">
          {{ 'BID_DELETE_PROHIBITED_1100' | translate }}
          {{ 'LOT_BIDDING_MODAL.IN_ACCORDANCE_WITH' | translate }}
          <a target="_blank" style="color: red" href="https://ecarjp.com/article/bid-rules">
            {{ 'LOT_BIDDING_MODAL.BID_RULES' | translate }}
          </a>
        </p>
      </ng-template>
      <ng-template #message1700>
        <p style="font-size: 14px">
          {{ 'BID_DELETE_PROHIBITED_1700' | translate }}
          {{ 'LOT_BIDDING_MODAL.IN_ACCORDANCE_WITH' | translate }}
          <a target="_blank" style="color: red" href="https://ecarjp.com/article/bid-rules">
            {{ 'LOT_BIDDING_MODAL.BID_RULES' | translate }}
          </a>
        </p>
      </ng-template>

      <nz-form-item class="mb-5">
        <nz-form-control
          *ngIf="
            !hasTranslations &&
            !mayNotBeProcessed &&
            !(biddingDataQueryRef.data | async)?.isSanctioned
          "
        >
          <div id="createTranslation9">
            <label id="8" nz-checkbox formControlName="createTranslation">
              {{ 'LOT_BIDDING_MODAL.TRANSLATE_REQUEST' | translate }}
              <button nz-button nzType="link" (click)="notificationSettings()">
                <span style="text-decoration: underline">
                  {{ (this.queryRef.data | async)?.currentUser?.preferredLang | uppercase }}
                  <span
                    *ngIf="(this.queryRef.data | async)?.currentUser?.defaultTranslationMediaType"
                  >
                    ({{
                      'TRANSLATION_SETTINGS.TRANSLATION_MEDIA_TYPES.' +
                        (this.queryRef.data | async)?.currentUser?.defaultTranslationMediaType
                        | uppercase
                        | translate
                    }})
                  </span>
                </span>
              </button>
            </label>
          </div>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="mb-5">
        <nz-form-control [nzSpan]="24" nz-row>
          <nz-row nzAlign="middle">
            <label
              appDriverStep
              [overrideStepIndex]="10"
              [stepTitle]="'LOT_BIDDING_MODAL.GUIDE.NOTIFY_RES.TITLE' | translate"
              [stepDescription]="'LOT_BIDDING_MODAL.GUIDE.NOTIFY_RES.DESCR' | translate"
              [stepSide]="'left'"
              [stepAlign]="'start'"
              [popoverClass]="'lot-bid-modal'"
              id="10"
              nz-checkbox
              formControlName="notify"
            >
              {{ 'LOT_BIDDING_MODAL.NOTIFY_REQUEST' | translate }}
            </label>
            <div
              class="d-flex"
              nz-tooltip
              [nzTooltipTitle]="'LOT_BIDDING_MODAL.NOTIFY_REQUEST_TOOLTIP' | translate"
              nzTooltipPlacement="right"
            >
              <span
                style="font-size: 22px"
                nz-icon
                nzType="question-circle"
                nzTheme="outline"
              ></span>
            </div>
          </nz-row>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="mb-5">
        <nz-form-control>
          <label
            appDriverStep
            [overrideStepIndex]="11"
            [stepTitle]="'LOT_BIDDING_MODAL.GUIDE.BIDS_RULES.TITLE' | translate"
            [stepDescription]="'LOT_BIDDING_MODAL.GUIDE.BIDS_RULES.DESCR' | translate"
            [stepSide]="'left'"
            [stepAlign]="'start'"
            [popoverClass]="'lot-bid-modal'"
            id="11"
            nz-checkbox
            formControlName="agreement"
          >
            {{ 'LOT_BIDDING_MODAL.AGREEMENT_HINT1' | translate }}
            <a
              target="_blank"
              (click)="$event.stopPropagation()"
              style="color: red"
              href="https://ecarjp.com/article/bid-rules"
            >
              {{ 'LOT_BIDDING_MODAL.BID_RULES' | translate }}
            </a>
            {{ 'LOT_BIDDING_MODAL.AGREEMENT_HINT2' | translate }}
          </label>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="mb-5">
        <nz-form-control *ngIf="isDisassembly">
          {{ 'LOT_BIDDING_MODAL.NAVIS_RULES_HINT1' | translate }}
          <span class="color-red pointer" (click)="showNavisRules()">
            {{ 'LOT_BIDDING_MODAL.NAVIS_RULES_HINT2' | translate }}.
          </span>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item
        class="mb-5"
        *ngIf="['ru', 'RU'].includes((queryRef.data | async)?.currentUser?.countryIso)"
      >
        <nz-form-control [nzSpan]="24">
          <app-green-corner-switch
            appDriverStep
            [overrideStepIndex]="12"
            [stepTitle]="'LOT_BIDDING_MODAL.GUIDE.GREEN_CORNER.TITLE' | translate"
            [stepDescription]="'LOT_BIDDING_MODAL.GUIDE.GREEN_CORNER.DESCR' | translate"
            [stepSide]="'left'"
            [stepAlign]="'start'"
            [popoverClass]="'lot-bid-modal'"
            [lot]="lot"
            [bidConditionId]="form.value.conditionId"
            id="12"
            formControlName="isGreenCorner"
          ></app-green-corner-switch>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="mb-10" *ngIf="form?.value?.conditionId === SPECIAL_EQUIPMENT_ID">
        <nz-alert
          class="w-100"
          nzType="warning"
          [nzMessage]="('VAT' | translate) + ' (' + (form?.value?.amount || 0) * 0.1 + ' JPY)'"
          nzShowIcon
        >
        </nz-alert>
      </nz-form-item>
    </form>
  </main>
</nz-spin>

<footer *nzModalFooter>
  <button nz-button (click)="modal.close()" [disabled]="isPlacingBid$ | async">
    {{ 'CANCEL' | translate }}
  </button>
  <button
    nz-button
    nz-popconfirm
    nzType="primary"
    [ngClass]="{ 'is-green-corner': form.value.isGreenCorner }"
    [nzLoading]="isPlacingBid$ | async"
    [disabled]="submitDisabled$ | async"
    [nzPopconfirmTitle]="placeBidConfirmation"
    [nzPopconfirmOverlayStyle]="{ 'max-width': '300px' }"
    [nzCondition]="placeConfirmationNotNeed$ | async"
    [nzOkText]="'LOT_BIDDING_MODAL.PLACE_YOUR_BID' | translate"
    (nzOnConfirm)="checkBirthdateAndPalaceBid()"
  >
    <ng-template #placeBidConfirmation>
      <i nz-icon nzType="exclamation-circle" nzTheme="fill"></i>
      <div class="ant-popover-message-title">
        <div *ngIf="(bidMakeSense$ | async) === false">
          {{ 'LOT_BIDDING_MODAL.DOESNT_MAKE_SENSE' | translate }}
        </div>
        <div *ngIf="limits.canPalaceOnlyOneBid$ | async">
          {{ 'LOT_BIDDING_MODAL.CAN_PACE_ONLY_ONE_BID' | translate }}
          {{ 'LOT_BIDDING_MODAL.WANT_PLACE_BID' | translate }}
        </div>
        <div *ngIf="isSanctionedUtilization$ | async">
          {{ 'LOT_BIDDING_MODAL.IS_SANCTIONED_UTILIZATION_SURE' | translate }}
        </div>
      </div>
    </ng-template>
    {{ 'LOT_BIDDING_MODAL.PLACE_YOUR_BID' | translate }}
  </button>
</footer>
