import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents time data, ISO-8601 encoded UTC date string. */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  /** Получить аукцион по id */
  auction?: Maybe<Auction>;
  auctions?: Maybe<Array<Auction>>;
  averageObjectToTrip?: Maybe<Array<AverageObjectToTrip>>;
  averageObjectToTripByAuction?: Maybe<Array<AverageObjectToTripByAuction>>;
  averageTimeDeliveryDocs?: Maybe<Scalars['Int']>;
  averageTimeDeliveryFromAuction?: Maybe<Array<AverageTimeDeliveryFromAuction>>;
  carType?: Maybe<Array<CarType>>;
  chassisType?: Maybe<Array<Chassis>>;
  countries?: Maybe<Array<Country>>;
  customType?: Maybe<Array<CustomType>>;
  engines?: Maybe<Array<EngineType>>;
  makers?: Maybe<Array<Maker>>;
  marks?: Maybe<Array<Mark>>;
  models?: Maybe<Array<Model>>;
  /** Курс по банку MUFG JPT/CNY */
  mufgJpyToCnyExhangeRate?: Maybe<Scalars['Float']>;
  /** Курс по банку MUFG JPT/EUR */
  mufgJpyToEurExhangeRate?: Maybe<Scalars['Float']>;
  /** Курс по банку MUFG JPT/USD */
  mufgJpyToUsdExhangeRate?: Maybe<Scalars['Float']>;
  ports?: Maybe<Array<Port>>;
  /** Получить круговое время доставки для аукциона */
  roundDeliveryTime?: Maybe<RoundDeliveryTime>;
  sawType?: Maybe<Array<SawType>>;
};


export type QueryAuctionArgs = {
  id: Scalars['Int'];
};


export type QueryAverageObjectToTripArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryAverageObjectToTripByAuctionArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryAverageTimeDeliveryDocsArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryAverageTimeDeliveryFromAuctionArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCustomTypeArgs = {
  carTypeId?: Maybe<Scalars['Int']>;
};


export type QueryMarksArgs = {
  makerId?: Maybe<Scalars['Int']>;
  makerName?: Maybe<Scalars['String']>;
};


export type QueryModelsArgs = {
  markId?: Maybe<Scalars['Int']>;
  markName?: Maybe<Scalars['String']>;
};


export type QueryRoundDeliveryTimeArgs = {
  auctionId: Scalars['Int'];
};


export type QuerySawTypeArgs = {
  chassisTypeId?: Maybe<Scalars['Int']>;
  objectTypeId?: Maybe<Scalars['Int']>;
};

export type Auction = {
  __typename?: 'Auction';
  address?: Maybe<Scalars['String']>;
  auctionData?: Maybe<AuctionData>;
  auctionsCountryRestrictions?: Maybe<Array<AuctionsCountryRestrictions>>;
  auctionsDealerFees?: Maybe<Array<AuctionsDealerFees>>;
  auctionsDeliveryCosts?: Maybe<Array<AuctionsDeliveryCosts>>;
  /** Лого банка */
  bankLogo?: Maybe<S3File>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rating?: Maybe<AuctionRating>;
  stock?: Maybe<Stock>;
  ticketRules?: Maybe<Scalars['String']>;
};

export type AuctionData = {
  __typename?: 'AuctionData';
  auctionDealer?: Maybe<Scalars['Int']>;
  auctionDelivery?: Maybe<Scalars['Int']>;
  auctionDeliveryUndriveable?: Maybe<Scalars['Int']>;
  auctionSbor?: Maybe<Scalars['Int']>;
  auctionSborByNegotiations?: Maybe<Scalars['Int']>;
  carrier?: Maybe<Carrier>;
  costDelivery?: Maybe<Scalars['Float']>;
  destinationStock?: Maybe<Stock>;
  freight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  isNoGroup?: Maybe<Scalars['Boolean']>;
  isNoPallet?: Maybe<Scalars['Boolean']>;
  port?: Maybe<Port>;
  remoteness?: Maybe<AuctionRemotenessEnum>;
  sourceStocks?: Maybe<Array<Stock>>;
};

export type Carrier = {
  __typename?: 'Carrier';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Stock = {
  __typename?: 'Stock';
  /** Список активных секторов */
  activeSectors?: Maybe<Array<Sector>>;
  /** Вместимость */
  capacity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** Склад в Отару */
  inOtaru?: Maybe<Scalars['Boolean']>;
  /** Склад в Томакомай */
  inTomakomai?: Maybe<Scalars['Boolean']>;
  /** Склад в Тояме */
  inToyama?: Maybe<Scalars['Boolean']>;
  /** Склад аукциона */
  isAuction?: Maybe<Scalars['Boolean']>;
  /** Склад в ЯП */
  isJapan?: Maybe<Scalars['Boolean']>;
  /** Склад порта */
  isPort?: Maybe<Scalars['Boolean']>;
  /** Хранение на складе платное */
  isStoragePaid?: Maybe<Scalars['Boolean']>;
  /** Склад с которых можно делать выдачу */
  issueAllow?: Maybe<Scalars['Boolean']>;
  /** Склад судна */
  isVessel?: Maybe<Scalars['Boolean']>;
  /** Виртуальный склад */
  isVirtual?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
  /** Флаг отправки уведомления о прибытии на склад клиенту */
  notificationClient?: Maybe<Scalars['Boolean']>;
  /** Флаг отправки уведомления о прибытии на склад менеджеру */
  notificationManager?: Maybe<Scalars['Boolean']>;
  /** Список всех секторов */
  sectors?: Maybe<Array<Sector>>;
  /** Стоимость хранения */
  storageCost?: Maybe<Scalars['Int']>;
  /** Количество дней бесплатного хранения */
  storageFreeDays?: Maybe<Scalars['Int']>;
  /** Кол-во объектов в секторе */
  totalCars?: Maybe<Scalars['Int']>;
};

export type Sector = {
  __typename?: 'Sector';
  /** Вместимость */
  capacity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
  /** Порядок вывода секторов в списках (сортировка) */
  order?: Maybe<Scalars['Int']>;
  /** Кол-во объектов в секторе */
  totalCars?: Maybe<Scalars['Int']>;
};

export type Port = {
  __typename?: 'Port';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  portData?: Maybe<PortData>;
  stock?: Maybe<Stock>;
};

export type PortData = {
  __typename?: 'PortData';
  countryIso?: Maybe<Scalars['String']>;
  freight?: Maybe<Scalars['Float']>;
  freightM3?: Maybe<Scalars['Float']>;
  freightPart?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
};

export enum AuctionRemotenessEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type AuctionsCountryRestrictions = {
  __typename?: 'AuctionsCountryRestrictions';
  countryIso?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type AuctionsDealerFees = {
  __typename?: 'AuctionsDealerFees';
  commission?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['Int']>;
};

export type AuctionsDeliveryCosts = {
  __typename?: 'AuctionsDeliveryCosts';
  costDelivery?: Maybe<Scalars['Float']>;
  destinationCountryIso?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type S3File = {
  __typename?: 'S3File';
  /** Base64 файл */
  binaryBase64?: Maybe<Scalars['String']>;
  /** Ссылка через клаудфронт */
  cloudFrontPath?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Состояние файлов для файлов таможни */
  customDocumentStatus?: Maybe<FileCustomDocumentStatusEnum>;
  /** CDN c учётом разрешения файла и бакета (для старых файлов) */
  fullPathByExtension?: Maybe<Scalars['String']>;
  /** Ссылка на файл с учётом бакета */
  fullPathForFile?: Maybe<Scalars['String']>;
  /** Ссылка через бакет на прямую */
  fullPathForFileWithoutCdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** Путь в бакете */
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<UserShortInfo>;
};

/** Статусы загрузки файлов для таможни */
export enum FileCustomDocumentStatusEnum {
  /** Подтвержден */
  Accepted = 'ACCEPTED',
  /** Новый файл */
  New = 'NEW',
  /** Не изменялся */
  NotChanged = 'NOT_CHANGED'
}

export type UserShortInfo = {
  __typename?: 'UserShortInfo';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type AuctionRating = {
  __typename?: 'AuctionRating';
  /** Аукцион */
  auction?: Maybe<Auction>;
  /** Колличество оценок */
  evaluationCount?: Maybe<Scalars['Int']>;
  /** Колличество отзывов */
  feedbackCount?: Maybe<Scalars['Int']>;
  /** Id */
  id?: Maybe<Scalars['Int']>;
  /** Рейтинг аукциона */
  rating?: Maybe<Scalars['Float']>;
};

export type AverageObjectToTrip = {
  __typename?: 'AverageObjectToTrip';
  all?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  stockToPort?: Maybe<Scalars['Int']>;
  toStock?: Maybe<Scalars['Int']>;
};

export type AverageObjectToTripByAuction = {
  __typename?: 'AverageObjectToTripByAuction';
  auctions?: Maybe<Array<ObjectToTripByAuction>>;
  name?: Maybe<Scalars['String']>;
};

export type ObjectToTripByAuction = {
  __typename?: 'ObjectToTripByAuction';
  all?: Maybe<Scalars['Int']>;
  auction?: Maybe<Scalars['String']>;
  stockToPort?: Maybe<Scalars['Int']>;
  toStock?: Maybe<Scalars['Int']>;
};

export type AverageTimeDeliveryFromAuction = {
  __typename?: 'AverageTimeDeliveryFromAuction';
  maxAvg?: Maybe<Scalars['Int']>;
  minAvg?: Maybe<Scalars['Int']>;
  nameStock?: Maybe<Scalars['String']>;
};

export type CarType = {
  __typename?: 'CarType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
};

export type Chassis = {
  __typename?: 'Chassis';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  alfa2?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  engName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomType = {
  __typename?: 'CustomType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type EngineType = {
  __typename?: 'EngineType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Maker = {
  __typename?: 'Maker';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Mark = {
  __typename?: 'Mark';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Model = {
  __typename?: 'Model';
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type RoundDeliveryTime = {
  __typename?: 'RoundDeliveryTime';
  /** Ближайший рейс */
  nearTrip?: Maybe<RoundDeliveryTimeItem>;
  /**
   * Следующий ближайший рейс
   * @deprecated Will be delete
   */
  nextNearTrip?: Maybe<RoundDeliveryTimeItem>;
};

export type RoundDeliveryTimeItem = {
  __typename?: 'RoundDeliveryTimeItem';
  /** @deprecated Will be delete */
  dateArrivalVladivostok?: Maybe<Scalars['DateTime']>;
  /** @deprecated Will be delete */
  dateBuy?: Maybe<Scalars['DateTime']>;
  /** @deprecated Will be delete */
  dateNearTrip?: Maybe<Scalars['DateTime']>;
  dateOfIssue?: Maybe<Scalars['DateTime']>;
  /** @deprecated Will be delete */
  dateWithAuction?: Maybe<Scalars['DateTime']>;
};

export type SawType = {
  __typename?: 'SawType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type AuctionRatingQueryVariables = Exact<{
  auctionId: Scalars['Int'];
}>;


export type AuctionRatingQuery = { __typename?: 'Query', auction?: Maybe<{ __typename?: 'Auction', id?: Maybe<number>, rating?: Maybe<{ __typename?: 'AuctionRating', id?: Maybe<number>, rating?: Maybe<number>, evaluationCount?: Maybe<number> }> }> };

export type AuctionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AuctionsQuery = { __typename?: 'Query', auctions?: Maybe<Array<{ __typename?: 'Auction', id?: Maybe<number>, name?: Maybe<string>, ticketRules?: Maybe<string>, auctionData?: Maybe<{ __typename?: 'AuctionData', isNoGroup?: Maybe<boolean>, isNoPallet?: Maybe<boolean>, remoteness?: Maybe<AuctionRemotenessEnum> }> }>> };

export type MufgCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type MufgCurrenciesQuery = { __typename?: 'Query', mufgJpyToCnyExhangeRate?: Maybe<number>, mufgJpyToEurExhangeRate?: Maybe<number>, mufgJpyToUsdExhangeRate?: Maybe<number> };

export type TimeToShipQueryVariables = Exact<{
  auctionId: Scalars['Int'];
}>;


export type TimeToShipQuery = { __typename?: 'Query', averageObjectToTrip?: Maybe<Array<{ __typename?: 'AverageObjectToTrip', all?: Maybe<number>, name?: Maybe<string> }>> };

export const AuctionRatingDocument = gql`
    query auctionRating($auctionId: Int!) {
  auction(id: $auctionId) {
    id
    rating {
      id
      rating
      evaluationCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuctionRatingGQL extends Apollo.Query<AuctionRatingQuery, AuctionRatingQueryVariables> {
    document = AuctionRatingDocument;
    client = 'directory';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuctionsDocument = gql`
    query auctions {
  auctions {
    id
    name
    auctionData {
      isNoGroup
      isNoPallet
      remoteness
    }
    ticketRules
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuctionsGQL extends Apollo.Query<AuctionsQuery, AuctionsQueryVariables> {
    document = AuctionsDocument;
    client = 'directory';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MufgCurrenciesDocument = gql`
    query mufgCurrencies {
  mufgJpyToCnyExhangeRate
  mufgJpyToEurExhangeRate
  mufgJpyToUsdExhangeRate
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MufgCurrenciesGQL extends Apollo.Query<MufgCurrenciesQuery, MufgCurrenciesQueryVariables> {
    document = MufgCurrenciesDocument;
    client = 'directory';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TimeToShipDocument = gql`
    query timeToShip($auctionId: Int!) {
  averageObjectToTrip(id: $auctionId) {
    all
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TimeToShipGQL extends Apollo.Query<TimeToShipQuery, TimeToShipQueryVariables> {
    document = TimeToShipDocument;
    client = 'directory';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    